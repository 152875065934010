import React, { useContext, useEffect } from 'react';
import { css } from '@emotion/css';
import { Alert, Button, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { useNavigate } from 'react-router-dom';
import { appPaths } from 'consts/appPaths';
import { useInstanceQuery } from 'api/queries';
import { LinuxDashboards } from './dashboards/LinuxDashboards';
import { MacOsDashboards } from './dashboards/MacOsDashboards';
import { WindowsDashboards } from './dashboards/WindowsDashboards';
import { OSQuickstartContext } from './OSQuickstart';
import { onComponentClick, trackApi } from './track';
import { useInstallDashboards } from 'hooks/useInstallDashboards';

export const Visualize = () => {
  const styles = useStyles2(getStyles);
  const navigate = useNavigate();
  const { selectedOs } = useContext(OSQuickstartContext);

  const { data } = useInstanceQuery();
  const orgId = data?.orgId;

  if (!selectedOs) {
    navigate(`${appPaths.osQuickstarts}/select`);
  }

  const [ dashboardInstallSuccess, dashboardInstallError ] = useInstallDashboards(selectedOs)

  useEffect(() => {
    if (dashboardInstallSuccess) {
      trackApi('integration-install-success', orgId)
    } else if (dashboardInstallError) {
      trackApi('integration-install-error', orgId)
    }
  }, [dashboardInstallSuccess, dashboardInstallError, orgId])

  return (
    <>
      {dashboardInstallError &&
        <Alert severity='error' title='Error installing dashboards.  Please try again later.' />
      }
      {dashboardInstallSuccess && <div>
        <h3>3. Select and visualize a dashboard</h3>
        <p>Select one of the pre-built dashboards to get you up and running quickly</p>

        {selectedOs === 'linux' && <LinuxDashboards />}
        {selectedOs === 'macos' && <MacOsDashboards />}
        {selectedOs === 'windows' && <WindowsDashboards />}

        <div className={styles.buttonWrapper}>
          <Button
            variant="secondary"
            onClick={() => {
              onComponentClick('back-to-configure', orgId);
              navigate(`${appPaths.osQuickstarts}/configure`);
            }}
          >
            ← Install and configure Grafana Alloy
          </Button>
        </div>
      </div>
    }
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  buttonWrapper: css`
    display: flex;
    gap: 1rem;
    margin-top: 24px;
  `,
});
