import React from 'react';
import { css } from '@emotion/css';
import { useNavigate } from 'react-router-dom';

import { Button, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { appPaths } from 'consts/appPaths';
import { ActionType, trackAction } from 'utils/tracking';
import { FlowEventName } from 'types/FlowEventName';
import { useInstanceQuery } from 'api/queries'

export const Overview = () => {
  const styles = useStyles2(getStyles);

  const { data } = useInstanceQuery()
  const orgId = data?.orgId

  const navigate = useNavigate();

  function onComponentClick(key: string): void {
    trackAction(FlowEventName.QUICKSTARTS_FLOW_EVENT_NAME, key, ActionType.click, orgId);
  }

  return (
    <div className={styles.title}>
      <h3>1. Overview</h3>
      <div className={styles.overviewText}>
        This guide will help you set up a monitoring solution for tracking development metrics in Visual Studio Code
        using the VSCode Exporter, Grafana Alloy, and Grafana Cloud. <br />
        By the end of this setup, you&apos;ll be able to visualize key development metrics from your VSCode environment
        on Grafana dashboards, allowing for better insights into your coding activities.
        <br />
        <br />
        <h5>Prerequisites</h5>
        <span>Before you begin, ensure you have the following</span>
        <ul>
          <li>Visual Studio Code (VSCode) installed on your machine.</li>
          <li>Network permissions to allow communication over port 9931, which is used by the VSCode Exporter.</li>
        </ul>
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          variant="secondary"
          onClick={() => {
            onComponentClick('back-to-quickstarts');
            navigate(`${appPaths.quickstarts}`);
          }}
        >
          ← Back to Quickstarts
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onComponentClick('forward-to-exporter');
            navigate(`${appPaths.vscodeQuickstarts}/exporter`);
          }}
        >
          Install VSCode Exporter →
        </Button>
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  title: css`
    margin-left: 24px;
    margin-bottom: 24px;

    li {
      margin-left: 16px;
    }

    h5 {
      font-weight: 700;
      margin-bottom: 8px;
    }
  `,
  overviewText: css`
    margin-bottom: ${theme.spacing(3)};
    font-weight: 400;
    background-color: ${theme.colors.background.secondary};
    line-height: 22px;
    padding: 24px;
    margin-top: 24px;
  `,
  buttonWrapper: css`
    display: flex;
    gap: 1rem;
  `,
});
