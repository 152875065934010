import { appPaths } from 'consts/appPaths';

import dashboardTutorial from 'img/DashboardScreenshots/dashboardTutorial.png';
import integration from 'img/DashboardScreenshots/integration.png';
import { ComponentKey } from 'types/ComponentKey';

export interface Card {
  id: string;
  text: string;
  heading: string;
  title: string;
  mainAction: string;
  path: string;
}

export const cardData: Card[] = [
  {
    id: ComponentKey.OssMigrationGuideTutorial,
    text: 'This migration guide is designed to assist Grafana OSS users in seamlessly transitioning to Grafana Cloud.',
    heading: dashboardTutorial,
    title: 'Migrate from OSS to Grafana Cloud',
    mainAction: 'Read Now',
    path: appPaths.migrateoss,
  },
  {
    id: ComponentKey.SupabaseTutorial,
    text: "This tutorial will guide you on collecting Supabase metrics with a hosted collector. We'll guide you through each step to ensure you can monitor your application's performance seamlessly.",
    heading: dashboardTutorial,
    title: 'Collecting Supabase Metrics Using a Hosted Collector',
    mainAction: 'Read Now',
    path: appPaths.supabase,
  },
  {
    id: ComponentKey.LinuxTutorial,
    text: 'This tutorial will show you how to monitor a Debian-amd64 host, deploy the Grafana Agent to a Ubuntu host, push metrics to Grafana Cloud, and use the dashboards to visualize those metrics.',
    heading: integration,
    title: 'Monitoring a Linux host using the Linux Node integration',
    mainAction: 'Read Now',
    path: '/connections/add-new-connection/linux-node?page=alloy',
  },
  {
    id: ComponentKey.SelfHostedMetricsTutorial,
    text: "In this guide, you'll learn how to configure Prometheus's remote_write feature to ship Prometheus metrics to Grafana Cloud from self-hosted a self-hosted Kubernetes cluster",
    heading: integration,
    title: 'Send metrics to Grafana Cloud from self-hosted Prometheus in Kubernetes',
    mainAction: 'Read Now',
    path: appPaths.selfHostedMetrics,
  },
  {
    id: ComponentKey.MultipleLinuxHostsTutorial,
    text: 'This guide shows how to use the grafana_agent Ansible role to deploy and manage Grafana Agents across multiple Linux hosts so you can monitor them using Grafana Cloud',
    heading: integration,
    title: 'Monitoring multiple Linux hosts with Grafana Agent Role',
    mainAction: 'Read Now',
    path: appPaths.multipleLinuxHosts,
  },
  {
    id: ComponentKey.JsonApiTutorial,
    text: 'This quick start will show you how to create a dashboard using JSON Api and a publicly available Star Wars API',
    heading: dashboardTutorial,
    title: 'JSON API',
    mainAction: 'Read Now',
    path: appPaths.jsonApi,
  },
  {
    id: ComponentKey.ServerlessSetupTutorial,
    text: 'In this guide, you will learn how to set up serverless Monitoring with AWS lambda by configuring cloudwatch metrics and configuring scrape jobs using cloudformation',
    heading: integration,
    title: 'Serverless Monitoring With AWS Lambda',
    mainAction: 'Read Now',
    path: appPaths.serverlessMonitoring,
  },
  {
    id: ComponentKey.CSVTutorial,
    text: 'Fast track your exploratory data analysis by building a dashboard using CSV and google stocks data',
    heading: dashboardTutorial,
    title: 'Exploratory Data Analysis with CSV',
    mainAction: 'Read Now',
    path: appPaths.csv,
  },
];
