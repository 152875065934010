import React, { useEffect } from 'react';
import { css } from '@emotion/css';

import { useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { ActionType, trackAction } from 'utils/tracking';
import StepsList from 'components/Quickstarts/StepsList/StepsList';
import { FlowEventName } from 'types/FlowEventName';
import { getVSCodeQuickstartData } from './VSCodeQuickstart.data';
import vscode from 'img/vscode.svg';
import { useInstanceQuery } from 'api/queries';

const VSCodeQuickstart = ({ step }: { step?: string }) => {
  const styles = useStyles2(getVSCodeQuickstartStyles);
  const stepData = getVSCodeQuickstartData(step || 'overview');

  const { data } = useInstanceQuery();
  const orgId = data?.orgId;

  useEffect(() => {
    trackAction(
      FlowEventName.VSCODE_QUICKSTART_FLOW_EVENT_NAME,
      stepData.step,
      ActionType.load,
      orgId
    );
  }, [stepData, orgId]);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        <img alt="vscode logo" className={styles.icon} src={vscode} />
        VSCode Exporter Quickstart
      </h1>

      {!!stepData && (
        <>
          <div className={stepData.steps.length > 0 ? styles.contentOuter : styles.wrapper(!!stepData.header)}>
            {stepData.steps.length > 0 && <StepsList data={stepData.steps} />}
            <div className={styles.wizardBody(!!stepData.steps.length)}>{stepData.component}</div>
          </div>
        </>
      )}
    </div>
  );
};

const getVSCodeQuickstartStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: ${theme.spacing(2)};
    background-color: ${theme.colors.background.primary};
    min-width: 820px;
  `,
  title: css`
    padding-left: 24px;
  `,
  icon: css`
    width: 40px;
    height: 40px;
    margin-right: 10px;
  `,
  contentOuter: css`
    display: flex;
    flex-direction: row;
    height: 100%;
  `,
  wrapper: (hasHeader: boolean) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: ${theme.spacing(4)};
    padding-top: ${hasHeader ? theme.spacing(4) : theme.spacing(2)};
  `,
  wizardBody: (hasSteps: boolean) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: ${hasSteps ? theme.spacing(4) : theme.spacing(0)};
    max-width: 1200px;
    min-width: 524px;
  `,
});

export default VSCodeQuickstart;
