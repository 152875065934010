import React, { useCallback, useState } from 'react';

import { Field, RadioButtonGroup } from '@grafana/ui'

import { isAdmin } from 'utils/validation';

import CreateAccessPolicyToken from './CreateAccessPolicyToken';
import DeniedAccessPolicyToken from './DeniedAccessPolicyToken';
import ExistingAccessPolicyToken from './ExistingAccessPolicyToken';

export enum TokenType {
  Existing = 'existing_token',
  New = 'new_token',
}

interface AccessPolicyTokenProps {
  value: string;
  onChange: ({ value, type }: { value: string; type: TokenType }) => void;
  onError?: () => void;
  policy: string;
  scopes: string[];
  hideScopes?: boolean;
  hideExistingButton?: boolean;
}

const tokenOptions = [
  { label: 'Create a new token', value: TokenType.New },
  { label: 'Use existing token', value: TokenType.Existing },
];

export const AccessPolicyToken: React.FC<AccessPolicyTokenProps> = ({ value, onChange, onError, policy, scopes, hideScopes, hideExistingButton }) => {
  const [type, setType] = useState<TokenType>(TokenType.New);
  const change = useCallback((value: string) => onChange({ value, type }), [onChange, type]);

  if (!isAdmin) {
    return <DeniedAccessPolicyToken />;
  }

  return (
    <div>
      <Field>
        <RadioButtonGroup
          options={tokenOptions}
          value={type}
          onChange={setType}
          disabled={!!value}
        />
      </Field>
      {type === TokenType.New && <CreateAccessPolicyToken value={value} onChange={change} onError={onError} policy={policy} scopes={scopes} hideScopes={hideScopes} />}
      {type === TokenType.Existing && <ExistingAccessPolicyToken value={value} onChange={change} hideButton={hideExistingButton} />}
    </div>
  );
};
