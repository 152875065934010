import { useQuery } from '@tanstack/react-query';

const PLUGIN_ID = 'grafana-selfservetutorials-app'

type InstancesResponse = {
  regionSlug: string;
  hmInstancePromId: number;
  hmInstancePromUrl: string;
  hlInstanceId: number,
  hlInstanceUrl: string,
  id: number;
  orgId: number;
  url: string;
}

export const useInstanceQuery = () => useQuery({
  queryKey: ['instance'],
  queryFn: () =>
    fetch(`/api/plugin-proxy/${PLUGIN_ID}/grafanacom-api/instances`).then((res) =>
      res.json() as Promise<InstancesResponse>,
    ),
})
