import { ComponentKey } from 'types/ComponentKey';
import { FlowEventName } from 'types/FlowEventName';

export enum ActionType {
  click = 'click',
  visit = 'visit',
  load = 'load',
  api = 'api',
}

export const trackAction = (
  eventName: FlowEventName,
  componentKey: ComponentKey | string,
  action: ActionType,
  orgId: number | undefined,
  message?: string
) => {
  if (validTrackData(eventName, componentKey, action, orgId)) {
    (window as any).rudderanalytics?.track(eventName, {
      component_key: componentKey,
      action,
      org_id: orgId,
      message,
    });
  }
};

const validTrackData = (
  eventName: FlowEventName,
  componentKey: ComponentKey | string,
  action: ActionType,
  orgId: number | undefined
): boolean => {
  const missing = [];
  if (!eventName) {
    missing.push('eventName');
  }
  if (!componentKey) {
    missing.push('component_key');
  }
  if (!action) {
    missing.push('action');
  }
  if (!orgId) {
    missing.push('org_id');
  }

  if (!!missing.length) {
    const message = `Attention! ${missing.join(',')} ${
      missing.length > 1 ? 'are' : 'is'
    } not defined. The action will not be tracked`;
    console.error(message);
    return false;
  }
  return true;
};
