import React, { useState } from 'react';
import { css } from '@emotion/css';
import { useNavigate } from 'react-router-dom';
import { Button, useStyles2, Alert, Modal } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { appPaths } from 'consts/appPaths';
import { usePluginComponents } from '@grafana-cloud/ui';
import Markdown from 'markdown-to-jsx';
import configureAlloy from './configureAlloy.md'; 
import restartAlloy from './restartAlloy.md';
import vscodeQuickstart from './vscodeQuickstart.json';
import { extractBashMarkdown } from './helpers';
import { ActionType, trackAction } from 'utils/tracking';
import { FlowEventName } from 'types/FlowEventName';
import { useInstanceQuery } from 'api/queries'
import { installDashboard } from './installDashboard'
import { vsCodeConfig } from './alloyConfig';

interface CollectorInstallationInstructionsExtensionProps {
  installMode: string;
  showTestConnection: boolean;
  showOSSelection: boolean;
  showTitle: boolean;
  onTokenChange: (token: string) => void;
}

const bashExtractedText = {
  config: extractBashMarkdown(configureAlloy.toString())[0],
  restart: extractBashMarkdown(restartAlloy.toString())[0],
};

export const InstallAlloy = () => {
  const styles = useStyles2(getStyles);
  const { data: instanceData } = useInstanceQuery()
  const orgId = instanceData?.orgId

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [configBtnText, setConfigBtnText] = useState('⧉ Copy to clipboard');
  const [restartBtnText, setRestartBtnText] = useState('⧉ Copy to clipboard');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [token, setToken] = useState('');

  let configVars;

  if (token && instanceData?.hmInstancePromUrl && instanceData?.hmInstancePromId) {
    configVars = {
      metricsUrl: `${instanceData.hmInstancePromUrl}/api/prom/push`,
      metricsId: instanceData.hmInstancePromId.toString(),
      grafanaCloudApiKey: token,
      instanceName: 'default',
    }
  }

  const CollectorInstallationInstructionsExtension =
    usePluginComponents<CollectorInstallationInstructionsExtensionProps>({
      extensionPointId: 'grafana/grafana-collector-app/collector-installation-instructions/v1',
    })?.components?.[0];

  function onComponentClick(key: string): void {
    trackAction(FlowEventName.QUICKSTARTS_FLOW_EVENT_NAME, key, ActionType.click, orgId);
  }

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const onClipboardCopy = (key: 'config' | 'restart') => {
    onComponentClick(`copied-${key}`);
    navigator.clipboard.writeText(bashExtractedText[key]);
    if (key === 'config') {
      setConfigBtnText('✓ Copied to clipboard');
    } else {
      setRestartBtnText('✓ Copied to clipboard');
    }
  };

  const handleVisualizeClick = async () => {
    onComponentClick('forward-to-dashboard');
    try {
      const result = await installDashboard({
        slugUrl: instanceData?.url ?? '',
        dashboard: vscodeQuickstart,
        message: 'Creating VSCode Exporter Dashboard',
        folderTitle: 'Quickstart Onboarding',
        overwrite: true,
        dataSource: {
          uid: `${instanceData?.hmInstancePromId}`,
          name: `${instanceData?.hmInstancePromUrl}`,
        },
      });
      if (result.url) {
        navigate(result.url)
      }
    } catch {
      setShowAlert(true)
    }
  }

  return (
    <div className={styles.title}>
      <h3>3. Install and Configure Grafana Alloy</h3>
      <span className={styles.preamble}>
        Use{' '}
        <a
          target="_blank"
          href="https://grafana.com/docs/alloy/latest/"
          onClick={() => onComponentClick('grafana-alloy-new-tab')}
          rel="noreferrer"
        >
          Grafana Alloy
        </a>{' '}
        to collect your metrics and export them to a Prometheus instance
      </span>
      <Alert
        title="Grafana Alloy is a component-based telemetry collector. It focuses on ease of use, adaptability, and easy
        debugging."
        severity="info"
        className={styles.alert}
      />
      <h4>3.1. Install and Run Grafana Alloy</h4>
      <div className={styles.overviewText}>
        Install Grafana Alloy on the same machine where VSCode is running.
        <br />
        <br />
        Start the Grafana Alloy agent to begin collecting and forwarding metrics to Grafana Cloud.
        <br />
        <br />
        <Button
          variant="primary"
          onClick={() => {
            onComponentClick('install-alloy');
            setIsModalOpen(true);
          }}
        >
          Install Grafana Alloy
        </Button>
        <Modal
          title=""
          closeOnBackdropClick
          onClickBackdrop={onModalClose}
          onDismiss={onModalClose}
          closeOnEscape
          isOpen={isModalOpen}
        >
          {CollectorInstallationInstructionsExtension && (
            <CollectorInstallationInstructionsExtension
              showTestConnection={true}
              showOSSelection={true}
              showTitle={true}
              installMode={'alloy'}
              onTokenChange={(token) => {
                setToken(token);
              }}
            />
          )}
        </Modal>
      </div>
      <h4>3.2. Configure Grafana Alloy to collect metrics</h4>
      <div className={styles.overviewText}>
        After installation, the config is stored in <Markdown>```/etc/alloy/config.alloy```</Markdown>.
        <br />
        <br />
        Add the following configuration to collect metrics from the VSCode Exporter:
        <div>
          <pre>{vsCodeConfig(configVars)}</pre>
        </div>
        <Button variant="primary" fill="text" onClick={() => onClipboardCopy('config')}>
          {configBtnText}
        </Button>
      </div>
      <h4>3.3. Restart Grafana Alloy</h4>
      <div className={styles.overviewText}>
        Once you&apos;ve changed your configuration file, run the following command to restart Grafana Alloy for any
        changes to take effect.
        <br />
        <br />
        <Markdown>{restartAlloy.toString()}</Markdown>
        <Button variant="primary" fill="text" onClick={() => onClipboardCopy('restart')}>
          {restartBtnText}
        </Button>
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          variant="secondary"
          onClick={() => {
            onComponentClick('back-to-exporter');
            navigate(`${appPaths.vscodeQuickstarts}/exporter`);
          }}
        >
          ← Install VSCode Exporter
        </Button>

        <Button
          variant="primary"
          onClick={handleVisualizeClick}
        >
          Visualize metrics →
        </Button>
        {!!showAlert && (
          <Alert
            severity="error"
            title={
              'There was an error while trying to access the VSCode Exporter Dashboard.'
            }
          />
        )}
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  title: css`
    margin-left: 24px;
    margin-bottom: 24px;
    h4 {
      margin-top: 32px;
    }
  `,
  preamble: css`
    color: ${theme.colors.text.secondary};
    a {
      color: ${theme.colors.text.link};
      text-decoration: underline;
    }
  `,
  overviewText: css`
    margin-bottom: ${theme.spacing(4)};
    font-weight: 400;
    background-color: ${theme.colors.background.secondary};
    line-height: 22px;
    padding: 24px;
  `,
  buttonWrapper: css`
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
  `,
  alert: css`
    margin-top: 1rem;
  `,
});
