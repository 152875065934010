import React from 'react';
import { InstanceProvider } from 'api/InstanceProvider';
import { CSV } from 'components/BlogPosts/CSV/CSV';
import { JsonApi } from 'components/BlogPosts/JsonApi/JsonApi';
import { KubernetesSelfHostedMetrics } from 'components/BlogPosts/Kubernetes/KubernetesSelfHostedMetrics';
import { MonitorMultipleLinuxHosts } from 'components/BlogPosts/Linux/MonitorMultipleLinuxHosts';
import { MigrateOss } from 'components/BlogPosts/MigrateOSS/MigrateOSS';
import { ServerlessPlatformMonitoring } from 'components/BlogPosts/Serverless/ServerlessPlatformMonitoring';
import { Supabase } from 'components/BlogPosts/Supabase/Supabase';
import { DocsCards } from 'components/DocsCards/DocsCards';
import {  Navigate, Route, Routes } from 'react-router-dom';
import VSCodeQuickstart from 'components/Quickstarts/VSCodeQuickstart/VSCodeQuickstart'
import { getVSCodeQuickstartData, vscodeQuickstartSteps } from 'components/Quickstarts/VSCodeQuickstart/VSCodeQuickstart.data';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { getOSQuickstartData, osQuickstartSteps } from 'components/Quickstarts/OS/OSQuickstart.data';
import OSQuickstart from 'components/Quickstarts/OS/OSQuickstart';

const queryClient = new QueryClient()

export const App = () => {
  return (
    <InstanceProvider>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={<DocsCards />} />
          <Route path="self-hosted-metrics" element={<KubernetesSelfHostedMetrics />} />
          <Route path="monitor-multiple-linux-hosts" element={<MonitorMultipleLinuxHosts />} />
          <Route path="json-api" element={<JsonApi />} />
          <Route path="serverless-monitoring" element={<ServerlessPlatformMonitoring />} />
          <Route path="csv" element={<CSV />} />
          <Route path="supabase" element={<Supabase />} />
          <Route path="migrate-oss" element={<MigrateOss />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          {vscodeQuickstartSteps.map((step) => (
            <Route
              path={getVSCodeQuickstartData(step)?.route}
              key={step}
              element={<VSCodeQuickstart step={step} />}
            />
          ))}
          {osQuickstartSteps.map((step) => (
            <Route
              path={getOSQuickstartData(step)?.route}
              key={step}
              element={<OSQuickstart step={step} />}
            />
          ))}
        </Routes> 
       </QueryClientProvider>
    </InstanceProvider>
  );
};
