import React, { useContext, useEffect } from 'react';
import { Button, Icon, Text } from '@grafana/ui';
import { OSQuickstartContext, osOptions } from './OSQuickstart';
import * as api from 'api/api'
import { useRetryRequest } from '../../../hooks/useRetryRequest';
import { onComponentClick, trackApi } from './track';
import { useInstanceQuery } from 'api/queries';

export const TestConnection = () => {
  const { selectedOs } = useContext(OSQuickstartContext);
  const { data } = useInstanceQuery();
  const orgId = data?.orgId

  const promQuery = () => api.getPromQuery(osOptions[selectedOs!].metricsCheckQuery)
  const responseHasPromData = (response: Awaited<ReturnType<typeof api.getPromQuery>>) => response?.data?.result?.length > 0
  const [testState, testConnection] = useRetryRequest(promQuery, responseHasPromData, 2000, 12)

  useEffect(() => {
    if (testState === 'passed') {
      trackApi('test-connection-passed', orgId)
    } else if (testState === 'failed') {
      trackApi('test-connection-failed', orgId)
    }
  }, [testState, orgId])

  return <>
    <h3>Test Connection</h3>
    <p>Test that Grafana Alloy is collecting data and sending it to Grafana Cloud</p>
    <Button onClick={() => {
      onComponentClick('click-test-connection', orgId)
      testConnection()
    }} disabled={testState === 'started'}>Test Connection</Button>

    <div>
      {testState === 'started' && <Text color='info'>Checking connection. It might take up to a minute...</Text>}
      {testState === 'failed' && <Text color='error'><Icon name="exclamation-triangle" /> No metrics were found for this integration. For more information, see the documentation for troubleshooting.</Text>}
      {testState === 'passed' && <Text color='success'><Icon name="check" /> Awesome! The agent is good to go.</Text>}
    </div>
  </>
}
