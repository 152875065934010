
import React, { useEffect, useState } from 'react';
import { Button, Field, FieldValidationMessage, Input } from '@grafana/ui';
import { validateExistingToken } from '@grafana-cloud/collector';

interface ExistingAccessPolicyTokenProps {
  value: string;
  onChange: (value: string) => void;
  hideButton?: boolean;
}

const ExistingAccessPolicyToken: React.FC<ExistingAccessPolicyTokenProps> = ({ value, onChange, hideButton }) => {
  const [error, setError] = useState('');
  const [field, setField] = useState(value);

  useEffect(() => {
    setField(value);
  }, [value]);

  // TODO: Check existing scopes, error if needed?

  return (
    <>
      <Field label="Existing Token" description="Paste your existing token here and we will automatically add it to the script in the next step.">
        <>
          <Input
            onChange={(event) => {
              const newField = event.currentTarget.value;
              setField(newField);
              const tokenValidationResult = validateExistingToken(newField)
              if (tokenValidationResult !== true) {
                setError(tokenValidationResult)
              } else {
                setError('')
                hideButton && onChange(newField)
              }
            }}
            value={field}
            disabled={!hideButton && !!value}
          />
          {error && <FieldValidationMessage>{error}</FieldValidationMessage>}
        </>
      </Field>
      {!hideButton &&
        <div>
          <Button
            disabled={!!value || !!error || !field}
            onClick={() => {
              onChange(field)
            }}
          >
            Use token
          </Button>
        </div>
      }
    </>
  );
};

export default ExistingAccessPolicyToken;
