import React from 'react';
import { DashboardsList } from './DashboardsList';

import cpu from '../images/windows-cpu-system.png';
import disk from '../images/windows-disks.png';
import fleet from '../images/windows-fleet.png';
import logs from '../images/windows-logs.png';
import overview from '../images/windows-overview.png';

const dashboards = [
  { name: 'CPU and system', image: cpu, url: '/d/windows-system/windows-cpu-and-system', trackingId: 'windows-cpu-and-system' },
  { name: 'Disks and filesystem', image: disk, url: '/d/windows-disks/windows-disks-and-filesystems', trackingId: 'windows-disks-and-filesystems' },
  { name: 'Fleet overview', image: fleet, url: '/d/windows-fleet/windows-fleet-overview', trackingId: 'windows-fleet-overview' },
  { name: 'Logs', image: logs, url: '/d/windows-logs/windows-logs', trackingId: 'windows-logs' },
  { name: 'Overview', image: overview, url: '/d/windows-overview/windows-overview', trackingId: 'windows-overview' },
];

export const WindowsDashboards = () => {
  return <DashboardsList content={dashboards} nodeType="Windows" />;
};
