import { osNames, osOptions } from '../components/Quickstarts/OS/OSQuickstart';
import * as api from 'api/api'
import { getOrCreateFolder } from '../components/Quickstarts/VSCodeQuickstart/installDashboard'
import { useEffect, useState } from 'react';

const installDashboards = async (os: osNames) => {
  const folderUid = osOptions[os].folderUid
  const dashboardList = await api.getDashboardsFromIntegration(osOptions[os].integrationName)
  const folderName = dashboardList.data[0].folder_name
  await getOrCreateFolder(folderUid, folderName)
  await Promise.all(dashboardList.data.map((item: any) => {
    return api.createDashboard(item.dashboard, folderUid, true, 'creating dashboard from the os quickstart')
  }))
  return api.installIntegration(osOptions[os].integrationName)
}

export const useInstallDashboards = (os?: osNames) => {
  const [ dashboardInstallSuccess, setDashboardInstallSuccess ] = useState(false)
  const [ dashboardInstallError, setDashboardInstallError ] = useState(false)

  useEffect(() => {
    setDashboardInstallSuccess(false);
    setDashboardInstallError(false);

    if (!os) {
      return;
    }
      
    installDashboards(os).then(() => {
      setDashboardInstallSuccess(true)
    }).catch(() => {
      setDashboardInstallError(true)
    });
  }, [os])

  return [ dashboardInstallSuccess, dashboardInstallError ] as const

}
