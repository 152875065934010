import { ActionType, trackAction } from 'utils/tracking';
import { FlowEventName } from 'types/FlowEventName';

export function onComponentClick(key: string, orgId?: number): void {
  if (orgId) {
    trackAction(FlowEventName.OS_QUICKSTART_FLOW_EVENT_NAME, key, ActionType.click, orgId);
  }
}

export function onPageLoad(key: string, orgId?: number): void {
  if (orgId) {
    trackAction(FlowEventName.OS_QUICKSTART_FLOW_EVENT_NAME, key, ActionType.load, orgId);
  }
}

export function trackApi(key: string, orgId?: number): void {
  if (orgId) {
    trackAction(FlowEventName.OS_QUICKSTART_FLOW_EVENT_NAME, key, ActionType.api, orgId);
  }
}

