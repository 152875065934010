import React, { useContext } from 'react';
import { css } from '@emotion/css';
import { useStyles2, Button } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { useNavigate } from 'react-router-dom';
import { appPaths } from 'consts/appPaths';
import { useInstanceQuery } from 'api/queries';
import { TokenForm } from './TokenForm';
import { OSQuickstartContext } from './OSQuickstart';
import { TestConnection } from './TestConnection';
import { AlloyConfig } from './AlloyConfig';
import { SelectPlatform } from './SelectPlatform';
import { onComponentClick } from './track';

export const Configure = () => {
  const styles = useStyles2(getStyles);
  const navigate = useNavigate();

  const { selectedOs, token } = useContext(OSQuickstartContext);

  const { data } = useInstanceQuery();
  const orgId = data?.orgId;

  if (!selectedOs) {
    navigate(`${appPaths.osQuickstarts}/select`);
    return;
  }

  return (
    <div>
      <h3>2. Configure your monitoring setup</h3>
      <p>
        Follow these simple steps to quickly install, run and configure Grafana Alloy to start monitoring your Linux OS
      </p>

      {(selectedOs === 'linux' || selectedOs === 'macos') && (
        <>
          <h3>2.1{')'} Select platform</h3>
          <div className={styles.section}>
            <SelectPlatform />
          </div>
        </>
      )}

      <h3>{selectedOs === 'linux' ? '2.2)' : '2.1)'} Use an API token</h3>
      <div className={styles.section}>
        <TokenForm />
      </div>

      {token && (
        <>
          <h3>{selectedOs === 'linux' ? '2.3)' : '2.2)'} Install, run and configure Grafana Alloy</h3>
          <div className={styles.section}>
            <AlloyConfig />
          </div>

          <div className={styles.section}>
            <TestConnection />
          </div>
        </>
      )}

      <div className={styles.buttonWrapper}>
        <Button
          variant="secondary"
          onClick={() => {
            onComponentClick('back-to-select', orgId);
            navigate(`${appPaths.osQuickstarts}/select`);
          }}
        >
          ← Select your OS
        </Button>
        <Button
          onClick={() => {
            onComponentClick('forward-to-visualize', orgId);
            navigate(`${appPaths.osQuickstarts}/visualize`);
          }}
          disabled={!token}
        >
          Select and visualize a dashboard →
        </Button>
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  section: css`
    background-color: ${theme.colors.background.secondary};
    padding: 24px;
    margin-top: 16px;
    margin-bottom: 24px;
  `,
  platformForm: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
  `,
  copyIcon: css`
    margin-right: 4px;
  `,
  codeBlock: css`
    max-height: 600px;
  `,
  buttonWrapper: css`
    display: flex;
    gap: 1rem;
    margin-top: 24px;
  `,
});
