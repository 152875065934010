import React, { ChangeEvent, useContext, useState } from 'react';
import { css } from '@emotion/css';
import { Alert, Button, FieldValidationMessage, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import linux from 'img/linux.svg';
import windows from 'img/windows.svg';
import macos from 'img/macos.svg';
import { appPaths } from 'consts/appPaths';
import { useNavigate } from 'react-router-dom';
import { useInstanceQuery } from 'api/queries';
import { OSQuickstartContext, osNames } from './OSQuickstart';
import { onComponentClick } from './track';

const SelectCard = (props: {
  name: string;
  id: string;
  icon: string;
  selectedId: osNames | undefined;
  error: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const styles = useStyles2(getStyles);
  const checked = props.id === props.selectedId;

  return (
    <>
      <input
        type="radio"
        name="osSelect"
        id={props.id}
        className={styles.input}
        checked={checked}
        onChange={props.onChange}
      />
      <label htmlFor={props.id} className={`${styles.selectCard} ${checked && styles.selectCardChecked} ${props.error && styles.selectCardError}`}>
        <img alt={`${props.id} logo`} src={props.icon} />
        <span className={styles.osName}>{props.name}</span>
      </label>
    </>
  );
};

export const Select = () => {
  const styles = useStyles2(getStyles);
  const navigate = useNavigate();
  const { selectedOs, setSelectedOs, setArchitecture } = useContext(OSQuickstartContext);
  const [ formError, setFormError ] = useState(false)

  const { data } = useInstanceQuery();
  const orgId = data?.orgId;

  const osChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const os = event.target.id
    setSelectedOs(os as osNames);
    if (os === 'linux') {
      setArchitecture('amd64')
    } else if (os === 'macos') {
      setArchitecture('arm64')
    }
    setFormError(false)
  };

  return (
    <div>
      {formError && <Alert title='There are errors in the form.  Please correct them and try again!' severity='error' elevated onRemove={() => { setFormError(false) }}/>}
      <h3>Overview</h3>

      <div className={styles.overviewText}>
        <p>
          This Quickstart wizard will guide you through the process of setting up system monitoring for you operating
          system in Grafana Cloud.
        </p>
        <p>
          By the end of the wizard, you will have successfully installed and configured Grafana Alloy as a systemd
          service, linked your monitoring data via an API token, and set up a pre-built Grafana dashboard. This will
          enable real-time monitoring and visualization of key OS metrics such as CPU, memory usage, disk space, and
          more—helping users to ensure their system’s health and performance right from Grafana Cloud.
        </p>
      </div>

      <h3>1. Select your OS</h3>
      <div className={styles.cardSection} role="radiogroup">
        <SelectCard name={'Linux'} id="linux" icon={linux} selectedId={selectedOs} onChange={osChange} error={formError} />
        <SelectCard name={'Windows'} id="windows" icon={windows} selectedId={selectedOs} onChange={osChange} error={formError} />
        <SelectCard name={'macOS'} id="macos" icon={macos} selectedId={selectedOs} onChange={osChange} error={formError} />
      </div>
      {formError && <FieldValidationMessage>Selecting an option is required</FieldValidationMessage>}

      <div className={styles.buttonWrapper}>
        <Button
          variant="secondary"
          onClick={() => {
            onComponentClick('back-to-quickstarts', orgId);
            navigate(`${appPaths.quickstarts}`);
          }}
        >
          ← Back to Quickstarts
        </Button>
        <Button
          onClick={() => {
            onComponentClick(`select-os-${selectedOs}`, orgId)
            onComponentClick('forward-to-configure', orgId);
            if (selectedOs) {
              navigate(`${appPaths.osQuickstarts}/configure`);
            } else {
              setFormError(true)
            }
          }}
        >
          Configure Data →
        </Button>
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  title: css`
    margin-left: 24px;
    margin-bottom: 24px;

    li {
      margin-left: 16px;
    }

    h5 {
      font-weight: 700;
      margin-bottom: 8px;
    }
  `,
  overviewText: css`
    margin-bottom: ${theme.spacing(3)};
    font-weight: 400;
    background-color: ${theme.colors.background.secondary};
    line-height: 22px;
    padding: 24px;
    margin-top: 24px;
  `,
  buttonWrapper: css`
    display: flex;
    gap: 1rem;
    margin-top: 24px;
  `,
  cardSection: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    margin-top: 24px;
    margin-bottom: 4px;
  `,
  selectCard: css`
    background-color: ${theme.colors.background.secondary};
    padding: ${theme.spacing(3)};
    border-radius: 8px;
    border: 2px solid ${theme.colors.background.secondary};

    :hover {
      background-color: ${theme.colors.emphasize(theme.colors.background.secondary)}
    }
  `,
  selectCardChecked: css`
    border: 2px solid ${theme.colors.text.link};
  `,
  selectCardError: css`
    border: 2px solid ${theme.colors.error.main};
  `,
  cardIcon: css`
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: ${theme.spacing(2.5)};
  `,
  cardTitle: (titleAlign: string | undefined = '', isInModal?: boolean) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
    flex: 1;
    line-height: 22px;
    font-weight: 400;
    margin-bottom: 0;
    text-align: ${titleAlign};
    font-size: ${theme.typography.h4.fontSize} !important;

    ${isInModal !== undefined && isInModal === true ? `font-size: 14px;` : `font-size: 16px;`}

    h4 {
      margin-bottom: 0;
    }
  `,
  input: css`
    position: absolute;
    opacity: 0;
  `,
  osName: css`
    padding-left: 16px;
    vertical-align: middle;
  `,
});
