import { InstallDashboardRequest } from 'types/Dashboard';
import { getFolderUid } from 'utils/dashboardsUtils';
import * as api from 'api/api'

export const getOrCreateFolder = async (uid: string, folderTitle: string) => {
  const foldersResponse = await api.folders()
  const folder = foldersResponse.find((folder) => folder.title === folderTitle);
  if (folder) {
    return folder
  } else {
    const folderCreated = await api.createFolder(uid, folderTitle)
    return folderCreated
  }
};

const getOrCreateDashboard = async (folderId: number, uid: string, dashboard: any, message: string, slugUrl: string, dataSource: {name: string, uid: string}) => {
  const dashboardsResponse = await api.dashboards(folderId)

  const dashboardFound = dashboardsResponse.find(
    (dashDB) => dashDB.title === dashboard.title
  );
  // create the dashboard and override it if already exists
  const dashboardUpdated = JSON.parse(
    JSON.stringify(dashboard)
      .replaceAll('$slugUrl', slugUrl)
      .replaceAll('$dataSourceName', dataSource.name)
      .replaceAll('$dataSourceUID', dataSource.uid)
  );
  
  const dashboardCreated = await api.createDashboard(dashboardUpdated, uid, Boolean(dashboardFound), message)
  return dashboardCreated
};

export const installDashboard = async (args: InstallDashboardRequest) => {
  const { folderTitle, dashboard, message, slugUrl, dataSource } = args;
  const uid = getFolderUid(folderTitle);

  const folder = await getOrCreateFolder(uid, folderTitle);

  return getOrCreateDashboard(folder.id, uid, dashboard, message, slugUrl, dataSource);
}
