import React, { useContext, useState } from 'react';
import { Alert, Button, Icon, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { OSQuickstartContext } from './OSQuickstart';
import { useInstanceQuery } from 'api/queries';

import { debianRedhatAmd } from './alloyConfigs/debian-redhat-amd';
import { debianRedhatArm } from './alloyConfigs/debian-redhat-arm';
import { macOsAmd } from './alloyConfigs/macos-amd';
import { macOsArm } from './alloyConfigs/macos-arm';
import { otherAmd } from './alloyConfigs/other-amd';
import { otherArm } from './alloyConfigs/other-arm';
import { windows } from './alloyConfigs/windows';
import { onComponentClick } from './track';

export type ConfigVars = { metricsUrl: string, metricsId: number, logsUrl: string, logsId: number, grafanaCloudApiKey: string, instanceName: string }

const getConfigFile = (os: string | undefined, platform: string, architecture: string, configVars: ConfigVars) => {
  if (platform === 'redhat') {
    platform = 'debian' //debian and redhat use same config file
  }
  if (os === 'linux' && platform === 'debian' && architecture === 'amd64') {
    return debianRedhatAmd(configVars)
  } else if (os === 'linux' && platform === 'debian' && architecture === 'arm64') {
    return debianRedhatArm(configVars)
  } else if (os === 'linux' && platform === 'other' && architecture === 'amd64') {
    return otherAmd(configVars)
  } else if (os === 'linux' && platform === 'other' && architecture === 'arm64') {
    return otherArm(configVars)
  } else if (os === 'macos' && architecture === 'amd64') {
    return macOsAmd(configVars)
  } else if (os === 'macos' && architecture === 'arm64') {
    return macOsArm(configVars)
  } else if (os === 'windows') {
    return windows(configVars)
  } else {
    return ''
  }
}

export const AlloyConfig = () => {
  const styles = useStyles2(getStyles);
  const { selectedOs, platform, architecture, token } = useContext(OSQuickstartContext);
  const [ configCopied, setConfigCopied ] = useState(false)
  const { data: instanceData } = useInstanceQuery();

  if (
    !instanceData?.hmInstancePromUrl ||
    instanceData?.hmInstancePromId === undefined ||
    !instanceData?.hlInstanceUrl ||
    instanceData?.hlInstanceId === undefined
  ) {
    return <Alert title="Unable to load instance data.  Please try again later." severity="error" />
  }

  const configTemplateVars = {
    metricsUrl: `${instanceData.hmInstancePromUrl}/api/prom/push`,
    metricsId: instanceData.hmInstancePromId,
    logsUrl: `${instanceData.hlInstanceUrl}/loki/api/v1/push`,
    logsId: instanceData.hlInstanceId,
    grafanaCloudApiKey: token,
    instanceName: 'default'
  }

  const configText = getConfigFile(selectedOs, platform, architecture, configTemplateVars)

  const onClipboardCopy = () => {
    onComponentClick('click-clipboard-copy', instanceData.orgId)
    navigator.clipboard.writeText(configText);
    setConfigCopied(true);
  };

  return (<>
    <Alert severity="info" title={''}>
      Grafana Alloy is a component-based telemetry collector. It focuses on ease of use, adaptability to your
      needs, and easy debugging.
    </Alert>
    <p>Run this command to install, run and configure Grafana Alloy as a alloy.service systemd service</p>
    <div>
      <pre className={styles.codeBlock}>{configText}</pre>
    </div>
    <Button size="md" fill="text" onClick={onClipboardCopy}>
      {!configCopied && <>
        <Icon name="copy" className={styles.copyIcon} /> Copy to clipboard
      </>}
      {configCopied && <>
        <Icon name="check" className={styles.copyIcon} /> Copied to clipboard
      </>}
    </Button>
  </>)
}

const getStyles = (theme: GrafanaTheme2) => ({
  copyIcon: css`
    margin-right: 4px;
  `,
  codeBlock: css`
    max-height: 600px;
  `,
});
