import React from 'react';

import { SetupWizardStepData } from 'components/Quickstarts/StepsList/StepsListTypes';
import { appPaths } from 'consts/appPaths';
import { TestGroup } from 'types/TestGroup';
import { Overview } from './Overview';
import { InstallExporter } from './InstallExporter';
import { InstallAlloy } from './InstallAlloy';

const vscodeQuickstartData: { [key: string]: SetupWizardStepData } = {
  overview: {
    step: 'overview',
    steps: [
      {
        title: 'Overview',
        stepNumber: '1.',
        isActive: true,
        stepsListLink: `${appPaths.vscodeQuickstarts}/overview`,
        key: 'click-vscode-quickstart-overview-nav-link',
      },
      {
        title: 'Install VSCode Exporter',
        stepNumber: '2.',
        isActive: false,
        stepsListLink: `${appPaths.vscodeQuickstarts}/exporter`,
        key: 'click-vscode-quickstart-exporter-nav-link',
      },
      {
        title: 'Install and Configure Grafana Alloy',
        stepNumber: '3.',
        isActive: false,
        stepsListLink: `${appPaths.vscodeQuickstarts}/alloy`,
        key: 'click-vscode-quickstart-alloy-nav-link',
      },
      {
        title: 'Visualize metrics',
        stepNumber: '4.',
        isActive: false,
        stepsListLink: '',
        key: 'click-vscode-quickstart-metrics-nav-link',
      },
    ],
    header: '1. Overview',
    preamble: '',
    cards: [],
    buttons: {
      back: {
        text: 'Back',
        link: `${appPaths.quickstarts}`,
        key: 'back-to-general-onboarding-flow-quickstarts-page',
      },
    },
    path: '',
    route: '/quickstart/vscode/overview',
    component: <Overview />,
  },
  exporter: {
    step: 'exporter',
    steps: [
      {
        title: 'Overview',
        stepNumber: '1.',
        isActive: false,
        stepsListLink: `${appPaths.vscodeQuickstarts}/overview`,
        key: 'click-vscode-quickstart-overview-nav-link',
      },
      {
        title: 'Install VSCode Exporter',
        stepNumber: '2.',
        isActive: true,
        stepsListLink: `${appPaths.vscodeQuickstarts}/exporter`,
        key: 'click-vscode-quickstart-exporter-nav-link',
      },
      {
        title: 'Install and Configure Grafana Alloy',
        stepNumber: '3.',
        isActive: false,
        stepsListLink: `${appPaths.vscodeQuickstarts}/alloy`,
        key: 'click-vscode-quickstart-alloy-nav-link',
      },
      {
        title: 'Visualize metrics',
        stepNumber: '4.',
        isActive: false,
        stepsListLink: '',
        key: 'click-vscode-quickstart-metrics-nav-link',
      },
    ],
    header: '2. Install VSCode Exporter',
    preamble: '',
    cards: [],
    buttons: {
      back: {
        text: '',
        link: ``,
        key: '',
      },
    },
    path: 'Quickstarts Config',
    route: '/quickstart/vscode/exporter',
    component: <InstallExporter />,
  },
  alloy: {
    step: 'alloy',
    steps: [
      {
        title: 'Overview',
        stepNumber: '1.',
        isActive: false,
        stepsListLink: `${appPaths.vscodeQuickstarts}/overview`,
        key: 'click-vscode-quickstart-overview-nav-link',
      },
      {
        title: 'Install VSCode Exporter',
        stepNumber: '2.',
        isActive: false,
        stepsListLink: `${appPaths.vscodeQuickstarts}/exporter`,
        key: 'click-vscode-quickstart-exporter-nav-link',
      },
      {
        title: 'Install and Configure Grafana Alloy',
        stepNumber: '3.',
        isActive: true,
        stepsListLink: `${appPaths.vscodeQuickstarts}/alloy`,
        key: 'click-vscode-quickstart-alloy-nav-link',
      },
      {
        title: 'Visualize metrics',
        stepNumber: '4.',
        isActive: false,
        stepsListLink: '',
        key: 'click-vscode-quickstart-metrics-nav-link',
      },
    ],
    header: '3. Install and Configure Grafana Alloy',
    preamble: '',
    cards: [],
    buttons: {
      back: {
        text: '',
        link: ``,
        key: '',
      },
    },
    path: 'Quickstarts Config',
    route: '/quickstart/vscode/alloy',
    component: <InstallAlloy />,
  },
  metrics: {
    step: 'metrics',
    steps: [
      {
        title: 'Overview',
        stepNumber: '1.',
        isActive: false,
        stepsListLink: `${appPaths.vscodeQuickstarts}/overview`,
        key: 'click-vscode-quickstart-overview-nav-link',
      },
      {
        title: 'Install VSCode Exporter',
        stepNumber: '2.',
        isActive: false,
        stepsListLink: `${appPaths.vscodeQuickstarts}/exporter`,
        key: 'click-vscode-quickstart-exporter-nav-link',
      },
      {
        title: 'Install and Configure Grafana Alloy',
        stepNumber: '3.',
        isActive: false,
        stepsListLink: `${appPaths.vscodeQuickstarts}/alloy`,
        key: 'click-vscode-quickstart-alloy-nav-link',
      },
      {
        title: 'Visualize metrics',
        stepNumber: '4.',
        isActive: true,
        stepsListLink: '',
        key: 'click-vscode-quickstart-metrics-nav-link',
      },
    ],
    header: '4. Visualize metrics',
    preamble: '',
    cards: [],
    buttons: {
      back: {
        text: '',
        link: ``,
        key: '',
      },
    },
    path: 'Create a dashboard / Create your own',
    route: '/quickstart/vscode/metrics',
    component: <></>
  },
};

const stepsOnExperiments: string[] = [];

export const vscodeQuickstartSteps = [...new Set(Object.values(vscodeQuickstartData).map((obj) => obj.step))] as const;

export const getVSCodeQuickstartData = (step: string, testGroup = TestGroup.CONTROL): SetupWizardStepData => {
  const stepId = stepsOnExperiments.includes(step) ? `${step}-${testGroup}` : step;
  return vscodeQuickstartData[stepId];
};
