import React, { useCallback, useContext } from 'react';

import { AccessPolicyToken, TokenType } from 'components/AccessPolicyToken/AccessPolicyToken';
import { InstanceContext } from 'api/InstanceProvider';

import { OSQuickstartContext } from './OSQuickstart';
import { onComponentClick, trackApi } from './track';

const tokenScopes = ['set:self-serve-tutorials-data-write'];

export const TokenForm = () => {
  const { token, setToken } = useContext(OSQuickstartContext);
  const { instanceData } = useContext(InstanceContext);
  const stack = instanceData?.id;
  const org = instanceData?.orgId;

  const onChange = useCallback(({ value, type }: { value: string; type: TokenType }) => {
    if (type === TokenType.Existing) {
      onComponentClick('click-use-existing-token', org);
    }
    if (type === TokenType.New) {
      onComponentClick('click-create-token', org)
    }

    setToken(value);
  }, [org, setToken]);

  const onError = () => {
    trackApi('create-token-error', org)
  }

  return (
    <AccessPolicyToken
      value={token}
      onChange={onChange}
      onError={onError}
      scopes={tokenScopes}
      policy={`stack-${stack}-quickstart`}
      hideScopes
    />
  );
};
