import React, { useContext } from 'react';
import { Field, Select, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { architectureOptions, OSQuickstartContext, platformOptions } from './OSQuickstart';
import { useInstanceQuery } from 'api/queries';
import { onComponentClick } from './track';

export const SelectPlatform = () => {
  const styles = useStyles2(getStyles);
  const { selectedOs, platform, setPlatform, architecture, setArchitecture } = useContext(OSQuickstartContext);
  const { data } = useInstanceQuery();
  const orgId = data?.orgId;

  return (
    <div className={styles.platformForm}>
      {selectedOs === 'linux' &&
        <Field label="Select platform">
            <Select
              options={platformOptions}
              value={platform}
              onChange={(platform) => {
                onComponentClick(`select-platform-${platform.value}`, orgId)
                if (platform.value) {
                  setPlatform(platform.value);
                }
              }}
            />
        </Field>
      }
      <Field label="Select architecture">
        <Select
          options={architectureOptions}
          value={architecture}
          onChange={(architecture) => {
            onComponentClick(`select-architecture-${architecture.value}`, orgId)
            if (architecture.value) {
              setArchitecture(architecture.value);
            }
          }}
        />
      </Field>
    </div>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  platformForm: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
  `,
});
