import React from 'react';
import { DashboardsList } from './DashboardsList';

import cpu from '../images/macos-logs.png';
import disk from '../images/macos-overview.png';

const dashboards = [
  { name: 'CPU and system', image: cpu, url: 'd/darwin-logs/macos-logs', trackingId: 'macos-logs' },
  { name: 'Filesystem and disks', image: disk, url: '/d/darwin-overview/macos-overview', trackingId: 'macos-overview' },
];

export const MacOsDashboards = () => {
  return <DashboardsList content={dashboards} nodeType="macOS" />;
};
