import React from 'react';
import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Card, Icon, useStyles2 } from '@grafana/ui'

const getStyles = (theme: GrafanaTheme2) => ({
  card: css`
    background-color: ${theme.colors.warning.transparent};
  `,
  header: css`
    margin: 0px !important;
  `,
});

const DeniedAccessPolicyToken: React.FC = () => {
  const styles = useStyles2(getStyles);

  return (
    <Card className={styles.card}>
      <Card.Heading className={styles.header}>
        Permission denied
      </Card.Heading>
      <Card.Figure>
        <Icon name="exclamation-triangle" color="orange" />
      </Card.Figure>
      <Card.Description>
        Only admins have permissions to manage access policy tokens.
        Please login to an organization where you are an admin,
        or ask the admin to update your role.
      </Card.Description>
    </Card>
  );
};

export default DeniedAccessPolicyToken;
