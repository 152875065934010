import { Dashboard, DashboardFolder } from 'types/Dashboard';
import { isOwnIntegrationsAccessEnabled } from 'utils/featureToggles';

export const folders = async () => {
  const res = await fetch('/api/folders')

  return res.json() as Promise<DashboardFolder[]>
}

export const createFolder = async (uid: string, title: string) => {
  const res = await fetch('/api/folders', {
    method: 'POST',
    body: JSON.stringify({ uid, title }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    }
  })
  
  return res.json() as Promise<DashboardFolder>
}

export const dashboards = async (folderId: number) => {
  const res = await fetch(`/api/search?type=dash-db&folderIds=${folderId}`)
  
  return res.json() as Promise<Dashboard[]>
}

export const createDashboard = async (dashboard: string, folderUid: string, overwrite: boolean, message: string) => {
  const res = await fetch('/api/dashboards/db', {
    method: 'POST',
    body: JSON.stringify({dashboard, folderUid, overwrite, message}),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    }
  })

  return res.json()
}

export const getAccessPolicy = async (accessPolicyName: string, region: string): Promise<any> => {
  const res = await fetch(`/api/plugin-proxy/grafana-selfservetutorials-app/grafanacom-api/v1/accesspolicies?name=${accessPolicyName}&region=${region}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    }
  })

  return await res.json()
}

export type Realm = {
  type: string
  identifier: string
  labelPolicies: string[]
}

export const createAccessPolicy = async (name: string, displayName: string, scopes: string[], realms: Realm[], region: string): Promise<any> => {
  const res = await fetch(`/api/plugin-proxy/grafana-selfservetutorials-app/grafanacom-api/v1/accesspolicies?region=${region}`, {
    method: 'POST',
    body: JSON.stringify({name, displayName, scopes, realms}),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    }
  })

  return await res.json()
}

export const createToken = async (accessPolicyId: string, displayName: string, name: string, region: string): Promise<any> => {
  const res = await fetch(`/api/plugin-proxy/grafana-selfservetutorials-app/grafanacom-api/v1/tokens?region=${region}`, {
    method: 'POST',
    body: JSON.stringify({accessPolicyId, displayName, name}),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    }
  })

  return await res.json()
}

export const getDashboardsFromIntegration = async(integrationName: string): Promise<any> => {
  const res = await fetch(`/api/plugin-proxy/${isOwnIntegrationsAccessEnabled() ? 'grafana-selfservetutorials-app' : 'grafana-easystart-app'}/integrations-api-admin/integrations/${integrationName}/dashboards`, {
    method: 'POST',
    body: JSON.stringify({
      configuration: {
        configurable_logs: {
          logs_disabled: false
        }
      }
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    }
  })

  return await res.json()
}

export const installIntegration = async(integrationName: string): Promise<any> => {
  await fetch(`/api/plugin-proxy/${isOwnIntegrationsAccessEnabled() ? 'grafana-selfservetutorials-app' : 'grafana-easystart-app'}/integrations-api-admin/integrations/${integrationName}/install`, {
    method: 'POST',
    body: JSON.stringify({
      configuration: {
        configurable_logs: {
          logs_disabled: false
        }
      }
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    }
  })

  return
}

export const getPromQuery = async(query: string): Promise<any> => {
  const res = await fetch(`/api/datasources/proxy/uid/grafanacloud-prom/api/v1/query?query=${encodeURIComponent(query)}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    }
  })

  return await res.json()
}
