import React from 'react';
import { css } from '@emotion/css';
import { useStyles2, Text } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { useInstanceQuery } from 'api/queries';
import { onComponentClick } from '../track';

export const DashboardsList = ({
  content,
  nodeType,
}: {
  content: Array<{ name: string; image: string, url: string, trackingId: string }>;
  nodeType: string;
}) => {
  const styles = useStyles2(getStyles);
  const { data } = useInstanceQuery();
  const orgId = data?.orgId

  return (
    <div className={styles.dashboards}>
      {content.map((item) => (
        <div className={styles.dashboard} key={item.name} onClick={() => {
          onComponentClick(`click-dashboard-${item.trackingId}`, orgId)
          window.open(item.url, '_blank')
        }}>
          <img src={item.image} className={styles.dashboardImage} />
          <Text variant="bodySmall" color="secondary" element="p">
            {nodeType}
          </Text>
          <Text variant="body">{item.name}</Text>
        </div>
      ))}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  dashboards: css`
    display: flex;
    flex-wrap: wrap;
  `,
  dashboard: css`
    background-color: ${theme.colors.background.secondary};
    padding: 16px;
    cursor: pointer;
    margin-right: 16px;
    margin-bottom: 16px;
  `,
  dashboardImage: css`
    width: 308px;
    height: 154px;
    margin-bottom: 8px;
  `,
});
