import React, { createContext, useEffect, useState } from 'react';
import { css } from '@emotion/css';

import { useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import StepsList from 'components/Quickstarts/StepsList/StepsList';
import { getOSQuickstartData } from './OSQuickstart.data';
import os from 'img/os.svg';
import { useInstanceQuery } from 'api/queries';
import { onPageLoad } from './track';

export const osOptions = {
  linux: {
    name: 'Linux',
    integrationName: 'linux-node',
    folderUid: 'integration---linux-node',
    metricsCheckQuery: 'vector(1) and on() ((count(up{job=~\"integrations/(node_exporter|unix)\"} == 1) > 0) and (absent(absent(node_uname_info{job=~\"integrations/(node_exporter|unix)\"})))) or vector(0) and on() ((count(up{job=~\"integrations/(node_exporter|unix)\"}) > 0) and (absent(node_uname_info{job=~\"integrations/(node_exporter|unix)\"})))',
  },
  macos: {
    name: 'Windows',
    integrationName: 'macos-node',
    folderUid: 'integration---macos-node',
    metricsCheckQuery: 'vector(1) and on() ((count(up{job=\"integrations/macos-node\"} == 1) > 0) and (absent(absent(node_uname_info{job=\"integrations/macos-node\"})))) or vector(0) and on() ((count(up{job=\"integrations/macos-node\"}) > 0) and (absent(node_uname_info{job=\"integrations/macos-node\"})))',
  },
  windows: {
    name: 'macOS',
    integrationName: 'windows-exporter',
    folderUid: 'integration---windows-exporter',
    metricsCheckQuery: 'vector(1) and on() ((count(up{job=~\"integrations/windows.*\"} == 1) > 0) and (absent(absent(windows_cs_hostname{job=~\"integrations/windows.*\"})))) or vector(0) and on() ((count(up{job=~\"integrations/windows.*\"}) > 0) and (absent(windows_cs_hostname{job=~\"integrations/windows.*\"})))',
  }
}

export type osNames = keyof typeof osOptions;

export const platformOptions = [
  { value: 'debian', label: 'Debian' },
  { value: 'redhat', label: 'RedHat' },
  { value: 'other', label: 'Other Distribution' },
];

export const architectureOptions = [
  { value: 'amd64', label: 'Amd64' },
  { value: 'arm64', label: 'Arm64' },
];

export const OSQuickstartContext = createContext({
  selectedOs: undefined as osNames | undefined,
  setSelectedOs: (state: osNames) => {},
  token: '',
  setToken: (state: string) => {},
  platform: 'debian',
  setPlatform: (state: string) => {},
  architecture: 'amd64',
  setArchitecture: (state: string) => {},
  tokenOption: 'new',
  setTokenOption: (state: string) => {},
});

const isOSName = (val: string): val is osNames => Object.keys(osOptions).includes(val);

const getQueryParam = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const os = queryParams.get('selected');

  return os && isOSName(os) ? os : undefined;
};

const OSQuickstart = ({ step }: { step?: string }) => {
  const styles = useStyles2(getOSQuickstartStyles);
  const [selectedOs, setSelectedOs] = useState<osNames | undefined>(getQueryParam);
  const [token, setToken] = useState('');
  const [platform, setPlatform] = useState<string>(platformOptions[0].value);
  const [architecture, setArchitecture] = useState<string>(architectureOptions[0].value);
  const [tokenOption, setTokenOption] = useState('new');
  const stepData = getOSQuickstartData(step || 'overview');

  const { data } = useInstanceQuery();
  const orgId = data?.orgId;

  useEffect(() => {
    onPageLoad(stepData.step, orgId)
  }, [stepData, orgId]);

  const disabledSteps = [
    false,
    selectedOs === undefined,
    !token
  ]

  return (
    <OSQuickstartContext.Provider
      value={{
        selectedOs,
        setSelectedOs,
        token,
        setToken,
        platform,
        setPlatform,
        architecture,
        setArchitecture,
        tokenOption,
        setTokenOption,
      }}
    >
      <div className={styles.container}>
        <h1 className={styles.title}>
          <img alt="monitor os logo" className={styles.icon} src={os} />
          Monitor your OS
        </h1>

        {!!stepData && (
          <>
            <div className={stepData.steps.length > 0 ? styles.contentOuter : styles.wrapper(!!stepData.header)}>
              {stepData.steps.length > 0 && <StepsList data={stepData.steps} disabledSteps={disabledSteps} />}
              <div className={styles.wizardBody(!!stepData.steps.length)}>{stepData.component}</div>
            </div>
          </>
        )}
      </div>
    </OSQuickstartContext.Provider>
  );
};

const getOSQuickstartStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: ${theme.spacing(2)};
    background-color: ${theme.colors.background.primary};
    min-width: 820px;
  `,
  title: css`
    padding-left: 24px;
  `,
  icon: css`
    width: 40px;
    height: 40px;
    margin-right: 10px;
  `,
  contentOuter: css`
    display: flex;
    flex-direction: row;
    height: 100%;
  `,
  wrapper: (hasHeader: boolean) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: ${theme.spacing(4)};
    padding-top: ${hasHeader ? theme.spacing(4) : theme.spacing(2)};
  `,
  wizardBody: (hasSteps: boolean) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: ${hasSteps ? theme.spacing(4) : theme.spacing(0)};
    max-width: 1200px;
    min-width: 524px;
  `,
});

export default OSQuickstart;
