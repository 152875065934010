import React from 'react';
import { DashboardsList } from './DashboardsList';

import cpu from '../images/linux-cpu-system.png';
import disk from '../images/linux-disk.png';
import fleet from '../images/linux-fleet-overview.png';
import logs from '../images/linux-logs.png';
import memory from '../images/linux-memory.png';
import network from '../images/linux-network.png';
import overview from '../images/linux-overview.png';

const dashboards = [
  { name: 'CPU and system', image: cpu, url: '/d/node-system/linux-node-cpu-and-system', trackingId: 'linux-node-cpu-and-system' },
  { name: 'Filesystem and disks', image: disk, url: '/d/node-disk/linux-node-filesystem-and-disks', trackingId: 'linux-node-filesystem-and-disks' },
  { name: 'Fleet overview', image: fleet, url: '/d/node-fleet/linux-node-fleet-overview', trackingId: 'linux-node-fleet-overview' },
  { name: 'Logs', image: logs, url: '/d/node-logs/linux-node-logs', trackingId: 'linux-node-logs' },
  { name: 'Memory', image: memory, url: '/d/node-memory/linux-node-memory', trackingId: 'linux-node-memory' },
  { name: 'Network', image: network, url: '/d/node-network/linux-node-network', trackingId: 'linux-node-network' },
  { name: 'Overview', image: overview, url: '/d/nodes/linux-node-overview', trackingId: 'linux-node-overview' },
];

export const LinuxDashboards = () => {
  return <DashboardsList content={dashboards} nodeType="Linux Node" />;
};
