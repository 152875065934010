import { FeatureToggles } from '@grafana/data';
import { config } from '@grafana/runtime';

enum SelfServeTutorialsFeatureToggle {
  ownIntegrationsAccess = 'selfServeTutorialsOwnIntegrationsAccess',
}

type SelfServeTutorialsFeatureToggles = FeatureToggles & {
  [key in SelfServeTutorialsFeatureToggle]: boolean;
};

function isFeatureToggleEnabled(featureName: SelfServeTutorialsFeatureToggle): boolean {
  return (config.featureToggles as SelfServeTutorialsFeatureToggles)[featureName] ?? false;
}

export function isOwnIntegrationsAccessEnabled(): boolean {
  return isFeatureToggleEnabled(SelfServeTutorialsFeatureToggle.ownIntegrationsAccess);
}
