import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getStepsListStyles = (theme: GrafanaTheme2) => ({
  contentInner: css`
    label: vertical;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    padding: ${theme.spacing(1)};
  `,
  pairBox: css`
    label: beside;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    padding-left: 24px;
  `,
  stepsBox: css`
    label: steps;
    border-right: 1px solid ${theme.colors.border.medium};
    width: 224px;
    margin-top: 20px;
    position: relative;
    border-left: 1px solid ${theme.colors.border.weak};
    padding: 0;
    ol {
      list-style: none;
      position: relative;
      margin: 0;
      left: -13px;
      counter-reset: stepcounter;
      padding-left: 32px;
    }
    ol li {
      counter-increment: stepcounter;
      position: relative;

      h4 {
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.045px;
      }

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
    ol li:before {
      content: counter(stepcounter);
      font-weight: bold;
      border-radius: 100%;
      position: absolute;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      top: -1px;
      padding: 4px;
      left: -32px;
      background-color: ${theme.colors.background.primary};
    }
  `,
  h4Link: css`
    a {
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.045px;
      margin-left: 0;
      padding: 0;
    }
  `,
  stepItem: (active: boolean) => css`
    color: ${active ? theme.colors.text.primary : theme.colors.text.disabled};

    a {
      color: ${active ? theme.colors.text.primary : theme.colors.text.disabled};
      height: 100%;
      span {
        white-space: pre-line;
      }
    }
  `,
});
