import React from 'react';
import { useNavigate } from 'react-router-dom';

import { LinkButton, useStyles2 } from '@grafana/ui';
import { getStepsListStyles } from './StepsList.styles';
import { ActionType, trackAction } from 'utils/tracking';
import { FlowEventName } from 'types/FlowEventName';
import { IndividualStep } from './StepsListTypes'
import { useInstanceQuery } from 'api/queries'

const StepsList = ({ data, disabledSteps }: { data: IndividualStep[], disabledSteps?: boolean[] }) => {
  const styles = useStyles2(getStepsListStyles);
  const instanceQueryResult = useInstanceQuery()
  const orgId = instanceQueryResult?.data?.orgId
  const navigate = useNavigate();

  function onComponentClick(stepsListLink: string, key: string): void {
    navigate(stepsListLink);
    trackAction(FlowEventName.QUICKSTARTS_FLOW_EVENT_NAME, key, ActionType.click, orgId);
  }

  return (
    <div className={styles.contentInner}>
      <div className={styles.pairBox}>
        <div className={styles.stepsBox}>
          <ol>
            {data?.map((elem, index) => {
              return (
                <li key={elem.title} className={styles.stepItem(elem.isActive)}>
                  {elem.stepsListLink && elem.key ? (
                    <h4 className={styles.h4Link}>
                      <LinkButton
                        fill="text"
                        onClick={() => elem.stepsListLink && elem.key && onComponentClick(elem.stepsListLink, elem.key)}
                        disabled={disabledSteps && disabledSteps[index]}
                      >
                        {elem.title}
                      </LinkButton>
                    </h4>
                  ) : (
                    <h4>{elem.title}</h4>
                  )}
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default StepsList;
