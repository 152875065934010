import React, { useCallback, useContext, useState } from 'react';
import { Alert, Badge, Button, Field, FieldValidationMessage, Icon, Input, Text, useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { validateName } from '@grafana-cloud/access-policies'

import * as api from 'api/api';
import { InstanceContext } from 'api/InstanceProvider';

const getStyles = (theme: GrafanaTheme2) => ({
  scope: css`
    background: ${theme.colors.background.canvas};
    border: 1px solid ${theme.colors.border.medium};
    color: ${theme.colors.text.primary};
  `,
});

const createToken = async (token: string, policy: string, scopes: string[], stack: number, region: string) => {
  const getAccessPolicyResponse = await api.getAccessPolicy(policy, region);
  let accessPolicyId = getAccessPolicyResponse.items[0]?.id;

  // TODO: Check existing scopes, patch if needed?

  if (!accessPolicyId) {
    const realms: api.Realm[] = [
      {
        type: 'stack',
        identifier: `${stack}`,
        labelPolicies: [],
      },
    ];
    const createAccessPolicyResponse = await api.createAccessPolicy(
      policy,
      policy,
      scopes,
      realms,
      region
    );
    accessPolicyId = createAccessPolicyResponse.id;
  }

  return api.createToken(accessPolicyId, `${policy}-${token}`, token, region)
};


interface CreateAccessPolicyTokenProps {
  value: string;
  onChange: (value: string) => void;
  onError?: () => void;
  policy: string,
  scopes: string[],
  hideScopes?: boolean,
}

const CreateAccessPolicyToken: React.FC<CreateAccessPolicyTokenProps> = ({ value, onChange, onError, policy, scopes, hideScopes }) => {
  const styles = useStyles2(getStyles);
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const { instanceData } = useContext(InstanceContext);
  const stack = instanceData?.id;
  const region = instanceData?.region;

  const create = useCallback(async () => {
    if (!region || !stack || loading) {
      return;
    }
    setLoading(true);

    const result = await createToken(name, policy, scopes, stack, region);
    if (result.token) {
      onChange(result.token);
    } else if (result.code === 'Conflict') {
      onError?.()
      setError('A token with this name already exists, please use a different name');
    } else {
      onError?.()
      setError('An error occurred, please try again later');
    }
    setLoading(false);
  }, [name, policy, scopes, stack, region, onChange, onError, loading]);

  if (!stack || !region) {
    return <Alert severity='error' title='Could not load instance data'>Please reload this page or try again later.</Alert>
  }
  
  return (
    <>
      <Field label="Token Name" description="Enter a descriptive name for this token">
        <>
          <Input
            onChange={(event) => {
              const newName = event.currentTarget.value.toLowerCase().replace(/[^a-z0-9-_]/g, '-');
              const position = event.currentTarget.selectionStart;

              event.currentTarget.value = newName;
              event.currentTarget.setSelectionRange(position, position);
              setName(newName);

              if (newName.length === 0) {
                setError('Token name is required');
                return;
              }

              const validationResult = validateName(newName);
              setError(typeof validationResult === 'string' ? validationResult : '');
            }}
            value={name}
            disabled={!!value}
            maxLength={63}
          />
          {error && <FieldValidationMessage>{error}</FieldValidationMessage>}
        </>
      </Field>
      {!hideScopes && (
        <Field label="Scopes" description="Predefined scopes for this token">
          <>
            {scopes.map((scope) => (
              <Badge key={scope} className={styles.scope} text={scope} color="blue" />
            ))}
          </>
        </Field>
      )}
      {!value && (
        <Button onClick={create} disabled={!!error || !name || loading}>
          Create token
        </Button>
      )}
      {value && (
        <Text element="p" color="success">
          <Icon name="check" /> Your token was generated - no need to do anything with it, we’ve automatically added it
          to the script in the next step.
        </Text>
      )}
    </>
  );
};

export default CreateAccessPolicyToken;
