import React from 'react';

import { SetupWizardStepData } from 'components/Quickstarts/StepsList/StepsListTypes';
import { appPaths } from 'consts/appPaths';
import { TestGroup } from 'types/TestGroup';
import { Select } from './Select';
import { Configure } from './Configure';
import { Visualize } from './Visualize';

const osQuickstartData: { [key: string]: SetupWizardStepData } = {
  select: {
    step: 'select',
    steps: [
      {
        title: 'Select your OS',
        stepNumber: '1.',
        isActive: true,
        stepsListLink: `${appPaths.osQuickstarts}/select`,
        key: 'click-os-quickstart-select-nav-link',
      },
      {
        title: 'Configure your monitoring setup',
        stepNumber: '2.',
        isActive: false,
        stepsListLink: `${appPaths.osQuickstarts}/configure`,
        key: 'click-os-quickstart-configure-nav-link',
      },
      {
        title: 'Select and visualize a dashboard',
        stepNumber: '3.',
        isActive: false,
        stepsListLink: `${appPaths.osQuickstarts}/visualize`,
        key: 'click-os-quickstart-visualize-nav-link',
      },
    ],
    header: '1. Select your OS',
    preamble: '',
    cards: [],
    buttons: {
      back: {
        text: 'Back',
        link: `${appPaths.quickstarts}`,
        key: 'back-to-general-onboarding-flow-quickstarts-page',
      },
    },
    path: '',
    route: '/quickstart/os/select',
    component: <Select />,
  },
  configure: {
    step: 'configure',
    steps: [
      {
        title: 'Select your OS',
        stepNumber: '1.',
        isActive: false,
        stepsListLink: `${appPaths.osQuickstarts}/select`,
        key: 'click-os-quickstart-select-nav-link',
      },
      {
        title: 'Configure your monitoring setup',
        stepNumber: '2.',
        isActive: true,
        stepsListLink: `${appPaths.osQuickstarts}/configure`,
        key: 'click-os-quickstart-configure-nav-link',
      },
      {
        title: 'Select and visualize a dashboard',
        stepNumber: '3.',
        isActive: false,
        stepsListLink: `${appPaths.osQuickstarts}/visualize`,
        key: 'click-os-quickstart-visualize-nav-link',
      },
    ],
    header: '2. Configure your monitoring setup',
    preamble: '',
    cards: [],
    buttons: {
      back: {
        text: '',
        link: ``,
        key: '',
      },
    },
    path: '',
    route: '/quickstart/os/configure',
    component: <Configure />,
  },
  visualize: {
    step: 'visualize',
    steps: [
      {
        title: 'Select your OS',
        stepNumber: '1.',
        isActive: false,
        stepsListLink: `${appPaths.osQuickstarts}/select`,
        key: 'click-os-quickstart-select-nav-link',
      },
      {
        title: 'Configure your monitoring setup',
        stepNumber: '2.',
        isActive: false,
        stepsListLink: `${appPaths.osQuickstarts}/configure`,
        key: 'click-os-quickstart-configure-nav-link',
      },
      {
        title: 'Select and visualize a dashboard',
        stepNumber: '3.',
        isActive: true,
        stepsListLink: `${appPaths.osQuickstarts}/visualize`,
        key: 'click-os-quickstart-visualize-nav-link',
      },
    ],
    header: '3. Select and visualize a dashboard',
    preamble: '',
    cards: [],
    buttons: {
      back: {
        text: '',
        link: ``,
        key: '',
      },
    },
    path: '',
    route: '/quickstart/os/visualize',
    component: <Visualize />,
  },
};

const stepsOnExperiments: string[] = [];

export const osQuickstartSteps = [...new Set(Object.values(osQuickstartData).map((obj) => obj.step))] as const;

export const getOSQuickstartData = (step: string, testGroup = TestGroup.CONTROL): SetupWizardStepData => {
  const stepId = stepsOnExperiments.includes(step) ? `${step}-${testGroup}` : step;
  return osQuickstartData[stepId];
};
