import pluginJson from 'plugin.json';

export const basePath = `/a/${pluginJson.id}`;
const quickstartsBasePath = `${basePath}/quickstart`;

export const appPaths = {
  basePath,
  linuxNode: `${basePath}/linux-node`,
  selfHostedMetrics: `${basePath}/self-hosted-metrics`,
  multipleLinuxHosts: `${basePath}/monitor-multiple-linux-hosts`,
  jsonApi: `${basePath}/json-api`,
  serverlessMonitoring: `${basePath}/serverless-monitoring`,
  csv: `${basePath}/csv`,
  supabase: `${basePath}/supabase`,
  migrateoss: `${basePath}/migrate-oss`,
  quickstarts: quickstartsBasePath,
  vscodeQuickstarts: `${quickstartsBasePath}/vscode`,
  osQuickstarts: `${quickstartsBasePath}/os`,
};
