import React from 'react';
import { css } from '@emotion/css';
import { useNavigate } from 'react-router-dom';

import { Button, Alert, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { appPaths } from 'consts/appPaths';
import { FlowEventName } from 'types/FlowEventName';
import { ActionType, trackAction } from 'utils/tracking';
import { useInstanceQuery } from 'api/queries'

export const InstallExporter = () => {
  const styles = useStyles2(getStyles);
  const { data } = useInstanceQuery()
  const orgId = data?.orgId
  const navigate = useNavigate();

  function onComponentClick(key: string): void {
    trackAction(FlowEventName.QUICKSTARTS_FLOW_EVENT_NAME, key, ActionType.click, orgId);
  }

  return (
    <div className={styles.title}>
      <h3>2. Install VSCode Exporter</h3>
      <br />
      <Alert
        title="This guide requires VSCode Exporter extension installed. If you already have it installed, you can skip this step."
        severity="info"
      />
      <div className={styles.overviewText}>
        Install the latest version of VSCode Exporter from the{' '}
        <a
          target="_blank"
          href="https://marketplace.visualstudio.com/items?itemName=guicaulada.vscode-exporter"
          onClick={() => onComponentClick('vscode-marketplace-new-tab')} rel="noreferrer"
        >
          Visual Studio Code Marketplace
        </a>
        <br />
        <br />
        Once installed, the VSCode Exporter will automatically start exporting your metrics to a Prometheus-compatible
        endpoint on port 9931 by default.
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          variant="secondary"
          onClick={() => {
            onComponentClick('back-to-overview');
            navigate(`${appPaths.vscodeQuickstarts}/overview`);
          }}
        >
          ← Overview
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onComponentClick('forward-to-alloy');
            navigate(`${appPaths.vscodeQuickstarts}/alloy`);
          }}
        >
          Install and Configure Grafana Alloy →
        </Button>
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  title: css`
    margin-left: 24px;
    margin-bottom: 24px;
  `,
  overviewText: css`
    margin-bottom: ${theme.spacing(3)};
    font-weight: 400;
    background-color: ${theme.colors.background.secondary};
    line-height: 22px;
    padding: 24px;
    margin-top: 24px;
    a {
      color: ${theme.colors.text.link};
      text-decoration: underline;
    }
  `,
  buttonWrapper: css`
    display: flex;
    gap: 1rem;
  `,
});
