type VSCodeConfigVars = {
  metricsUrl: string;
  metricsId: string;
  grafanaCloudApiKey: string;
  instanceName: string;
};

export const vsCodeConfig = (
  { metricsUrl, metricsId, grafanaCloudApiKey, instanceName }: VSCodeConfigVars = {
    metricsUrl: '<Your Prometheus URL>',
    metricsId: '<Your Username>',
    grafanaCloudApiKey: '<Your API Key>',
    instanceName: '<Your Hostname>',
  }
) =>
  String.raw`prometheus.scrape "vscode" {
  targets          = [{"__address__" = "localhost:9931", "__name__" = "vscode", "instance" = "${instanceName}"}]
  job_name         = "vscode"
  scrape_interval  = "10s"
  forward_to = [prometheus.remote_write.metrics_service.receiver]
}

prometheus.remote_write "metrics_service" {
  endpoint {
    url = "${metricsUrl}"
    basic_auth {
      username = "${metricsId}"
      password = "${grafanaCloudApiKey}"
    }
  }
}`;
