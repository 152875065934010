import { useEffect, useState, useRef } from 'react';

export const useRetryRequest = <T>(requestCallback: () => Promise<T>, responseSuccessCallback: (data: T) => boolean, delay: number, retries: number) => {
  const [testState, setTestState] = useState<'none' | 'started' | 'passed' | 'failed'>('none')
  const [_, setRetryCount] = useState(0)
  const retryIntervalRef = useRef<NodeJS.Timeout>();

  const handleResponse = (data: T) => {
    if (responseSuccessCallback(data)) {
      setTestState('passed')
      clearInterval(retryIntervalRef.current)
    } else {
      setRetryCount((retryCount) => {
        if (retryCount >= retries) {
          setTestState('failed')
          clearInterval(retryIntervalRef.current)
        }
        return retryCount + 1
      })
    }
  }

  const startTests = () => {
    setTestState('started')
    setRetryCount(0)
    requestCallback().then(handleResponse)
    retryIntervalRef.current = setInterval(() => {
      requestCallback().then(handleResponse)
    }, delay)
  }

  useEffect(() => {
    return () => {
      clearInterval(retryIntervalRef.current)
    }
  }, [])

  return [testState, startTests] as const
}
