export const alloyInstall = (
  arch: 'amd64' | 'arm64',
  promUrl: string | undefined,
  promId: number | undefined,
  lokiUrl: string | undefined,
  lokiId: number | undefined,
  token: string | undefined
) => {
  const hm = promUrl + '/api/prom/push';
  const hl = lokiUrl + '/loki/api/v1/push';
  return (
    `ARCH="${arch}"` +
    ` GCLOUD_HOSTED_METRICS_URL="${hm}"` +
    ` GCLOUD_HOSTED_METRICS_ID="${promId}"` +
    ` GCLOUD_SCRAPE_INTERVAL="60s"` +
    ` GCLOUD_HOSTED_LOGS_URL="${hl}"` +
    ` GCLOUD_HOSTED_LOGS_ID="${lokiId}"` +
    ` GCLOUD_RW_API_KEY="${token}"` +
    ` /bin/sh -c "$(curl -fsSL https://storage.googleapis.com/cloud-onboarding/alloy/scripts/install-linux.sh)"`
  );
};

export const ansibleConfig = `[defaults]
  inventory = inventory  # Path to the inventory file
  private_key_file = ~/.ssh/id_rsa   # Path to my private SSH Key
  remote_user=root
  `;

export const deployAgentYML = (
  promId: number | undefined,
  lokiId: number | undefined,
  promUrl: string | undefined,
  lokiUrl: string | undefined,
  token: string | undefined
) => `name: Install Grafana Agent
  hosts: all
  become: true
  
  vars:
    grafana_cloud_api_key: ${token ?? 'your-api-key'}        
    metrics_username: ${promId}                 
    logs_username: ${lokiId}                           
    prometheus_url: ${promUrl + '/api/prom/push'}                    
    loki_url: ${lokiUrl + '/loki/api/v1/push'}                                
  tasks: 
    - name: Install Grafana Agent
      ansible.builtin.include_role:
        name: grafana_agent
      vars:
        grafana_agent_metrics_config:
          configs:
            - name: integrations
              remote_write:
                - basic_auth:
                    password: "{{ grafana_cloud_api_key }}"
                    username: "{{ metrics_username }}"
                  url: "{{ prometheus_url }}"
  
          global:
            scrape_interval: 60s
          wal_directory: /tmp/grafana-agent-wal
  
        grafana_agent_logs_config:
          configs:
            - name: default
              clients:
                - basic_auth:
                    password: "{{ grafana_cloud_api_key }}"
                    username: "{{ logs_username }}"
                  url:  "{{ loki_url }}"
              positions:
                filename: /tmp/positions.yaml
              target_config:
                sync_period: 10s
              scrape_configs:
                - job_name: varlogs
                  static_configs:
                    - targets: [localhost]
                      labels:
                        instance: $/{HOSTNAME:-default}
                        job: varlogs
                        __path__: /var/log/*log
        grafana_agent_integrations_config:
          node_exporter:
            enabled: true
            instance: $/{HOSTNAME:-default}
          prometheus_remote_write:
            - basic_auth:
                password: "{{ grafana_cloud_api_key }}"
                username: "{{ metrics_username }}"
              url: "{{ prometheus_url }}"
  `;

export const inventoryFile = `146.190.208.216    # hostname = ubuntu-01
  146.190.208.190    # hostname = ubuntu-02
  137.184.155.128    # hostname = centos-01
  146.190.216.129    # hostname = centos-02
  198.199.82.174     # hostname = debian-01
  198.199.77.93      # hostname = debian-02
  143.198.182.156    # hostname = fedora-01
  143.244.174.246    # hostname = fedora-02
  `;
